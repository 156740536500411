var render = function render() {
  var _ref, _ref2, _ref3
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-card",
    {
      class: [
        {
          "card-lift--hover": _vm.hover,
        },
        {
          shadow: _vm.shadow,
        },
        ((_ref = {}),
        (_ref["shadow-".concat(_vm.shadowSize)] = _vm.shadowSize),
        _ref),
        ((_ref2 = {}),
        (_ref2["bg-gradient-".concat(_vm.gradient)] = _vm.gradient),
        _ref2),
        ((_ref3 = {}), (_ref3["bg-".concat(_vm.type)] = _vm.type), _ref3),
      ],
      attrs: {
        "no-body": "",
      },
    },
    [
      _vm._t("image"),
      _vm.$slots.header
        ? _c(
            "b-card-header",
            {
              class: _vm.headerClasses,
            },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      !_vm.noBody
        ? _c(
            "b-card-body",
            {
              class: _vm.bodyClasses,
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm.noBody ? _vm._t("default") : _vm._e(),
      _vm.$slots.footer
        ? _c(
            "b-card-footer",
            {
              class: _vm.footerClasses,
            },
            [_vm._t("footer")],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }