var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "label",
    {
      staticClass: "custom-toggle",
      class: _vm.switchClass,
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model,
            expression: "model",
          },
        ],
        attrs: {
          type: "checkbox",
        },
        domProps: {
          checked: Array.isArray(_vm.model)
            ? _vm._i(_vm.model, null) > -1
            : _vm.model,
        },
        on: {
          change: function change($event) {
            var $$a = _vm.model,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.model = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.model = $$c
            }
          },
        },
      }),
      _c("span", {
        staticClass: "custom-toggle-slider rounded-circle",
        attrs: {
          "data-label-off": _vm.offText,
          "data-label-on": _vm.onText,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }