var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-navbar",
    {
      class: _vm.classes,
      attrs: {
        toggleable: "",
      },
    },
    [
      _c(
        "div",
        {
          class: _vm.containerClasses,
        },
        [
          _vm._t("brand"),
          _vm._t("toggle-button", function () {
            return [
              _vm.hasMenu
                ? _c(
                    "button",
                    {
                      staticClass: "navbar-toggler collapsed",
                      attrs: {
                        type: "button",
                        "aria-expanded": "false",
                        "aria-label": "Toggle navigation",
                      },
                      on: {
                        click: _vm.toggleMenu,
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "navbar-toggler-bar navbar-kebab",
                      }),
                      _c("span", {
                        staticClass: "navbar-toggler-bar navbar-kebab",
                      }),
                      _c("span", {
                        staticClass: "navbar-toggler-bar navbar-kebab",
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          }),
          _c("b-navbar-toggle", {
            attrs: {
              target: "nav-text-collapse",
            },
            on: {
              click: function click($event) {
                $event.stopPropagation()
                return _vm.toggleMenu.apply(null, arguments)
              },
            },
          }),
          _c(
            "b-collapse",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeMenu,
                  expression: "closeMenu",
                },
              ],
              staticClass: "navbar-custom-collapse collapse show",
              class: _vm.menuClasses,
              attrs: {
                "is-nav": "",
                id: "nav-text-collapse",
                visible: _vm.show,
              },
            },
            [
              _vm._t("default", null, {
                closeMenu: _vm.closeMenu,
              }),
            ],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }